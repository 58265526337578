@font-face {
  font-family: haymakerregular;
  src: url(fonts/haymaker-webfont.eot);
  src: url(fonts/haymaker-webfont.eot?#iefix) format("embedded-opentype"),
    url(fonts/haymaker-webfont.woff) format("woff"),
    url(fonts/haymaker-webfont.ttf) format("truetype"),
    url(fonts/haymaker-webfont.svg#haymakerregular) format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: blanchcaps_inline;
  src: url(fonts/blanch_caps_inline-webfont.eot);
  src: url(fonts/blanch_caps_inline-webfont.eot?#iefix)
      format("embedded-opentype"),
    url(fonts/blanch_caps_inline-webfont.woff) format("woff"),
    url(fonts/blanch_caps_inline-webfont.ttf) format("truetype"),
    url(fonts/blanch_caps_inline-webfont.svg#blanchcaps_inline) format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: raleway;
  src: url(fonts/Raleway-Black.ttf) format("ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: radnika_next;
  src: url(fonts/radnikanext-medium-webfont.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: delicrom;
  src: url(fonts/DelicRom.ttf) format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: coalbrush;
  src: url(fonts/coalbrush.otf) format("opentype");
  font-weight: lighter;
  font-style: normal;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
}
a {
  color: inherit;
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: rgba(30, 29, 28, 0.99);
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: 2 1c 2 1d 2 18 2 19;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
  width: 100%;
}
svg:not(:root) {
  overflow: hidden;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
button {
  font-family: delicrom, sans-serif;
  padding: 0;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body,
figure {
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner,
legend {
  border: 0;
  padding: 0;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  font-size: 10px;
}
* :focus {
  outline: 0;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: delicrom, sans-serif;
  font-size: 2rem;
  background: #452274;
  color: #fff;
}
#main {
  min-height: 100vh;
}
h1 {
  font-size: 12rem;
}
h2 {
  font-size: 15rem;
}
.hero h2 {
  margin-left: 10rem;
  padding-right: 5rem;
}
h1,
h2 {
  font-family: blanchcaps_inline, sans-serif;
  text-align: center;
  font-weight: 400;
  margin: 0;
}
.nav {
  position: fixed;
  width: 100%;
  z-index: 500;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  padding: 0 2rem;
  transition: all 0.3s;
  cursor: pointer;
}
.nav .top {
  z-index: 551;
}
.nav > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  transition: all 0.3s;
  display: grid;
  grid-auto-flow: column;
}
.nav-button {
  font-size: 2rem;
  text-transform: uppercase;
  background: none;
  font-weight: 500;
  text-align: end;
  height: 100%;
  transition: color 0.3s;
  border: none;
  width: 100%;
}

.nav.scroll li button,
.nav.scroll li a {
  color: #fff;
}

a,
button {
  color: #272727;
}
a:hover,
button:hover {
  transition: 0.2s;
  color: #cfb4f5;
  outline: 0;
}
nav.nav ul a {
  text-transform: uppercase;
  background: none;
  font-weight: 500;
  align-content: center;
  cursor: pointer;
  display: grid;
  text-align: end;
  height: 100%;
  transition: color 0.2s;
}
.logolink {
  display: inline-flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
}
.logo {
  padding: 0.8rem 0 0 1rem;
}
.logoimg {
  width: 6rem;
}
#tagline {
  font-family: blanchcaps_inline;
  font-size: 7rem;
  display: grid;
}
#fc {
  font-family: coalbrush;
  font-size: 10rem;
  /* transform: translate(-2px, -8px); */
  text-align: center;
  font-style: italic;
}
.main > div {
  margin: 0 auto 0 auto;
  padding-top: 4em;
}
.app {
  max-width: 1500px;
}
.header {
  display: flex;
  position: relative;
}
.hero {
  position: absolute;
  top: 10rem;
  background-color: #452274;
}
.fishheader {
  text-align: right;
}
.right {
  float: right;
}
.fishheader img {
  width: 100%;
  max-height: 90rem;
  object-fit: cover;
}
.about-content {
  position: relative;
}
.btn-sub {
  text-align: end;
}

.btn-sub button {
  width: 10rem;
  padding: 0.5rem;
  text-transform: uppercase;
  border-radius: 2px;
}
.about-image img {
  width: 100%;
}
.member-image {
  display: grid;
}
.member-image img {
  height: 500px;
  object-fit: cover;
  object-position: top;
}
.about-p {
  align-self: center;
}
.about-p > div {
  /* background-color: #8c9ca5;
  box-shadow: 1px 1px 10px 0 #414141; */
  padding: 2rem 5rem;
  z-index: 2;
  font-size: 2.5rem;
}
.sponsor-content {
  padding: 5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.sponsor-img {
  object-fit: contain;
  width: 33.33%;
  max-height: 350px;
}
.all-sponsors {
  float: right;
  color: #fff;
  text-decoration: underline;
}
.main-sponsor-content,
.sponsor-content {
  background-color: #fff;
}
.main-sponsor-content {
  display: flex;
  flex-wrap: wrap;
}
.main-sponsor-content img {
  max-height: 250px;
  width: 33.33%;
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transition: all 0.2s;
}
/* Disable grayscale on hover */
.main-sponsor-content img:hover {
  -webkit-filter: grayscale(0);
  filter: none;
}
.sponsor-content {
  grid-template-columns: repeat(3, 1fr) !important;
}
.group-image,
.schedule,
.contact-parallax {
  position: relative;
}
.group-image {
  text-align: center;
}
.group-image img {
  width: 100%;
  box-shadow: 1px 1px 10px 0 #414141;
}
.hashtag {
  align-self: center;
}
.contact-image {
  align-self: center;
  object-fit: cover;
}
.contact-parallax {
  width: 100%;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 10rem;
}
.position img {
  width: 5rem;
}
.position-name {
  padding: 2rem;
  background-color: #452274;
  align-self: end;
  margin: 1em 0;
}
.image-gallery-prev {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.item-edit {
  margin-bottom: 20px;
  /* border: 2px solid rgba(30, 29, 28, 0.99); */
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
}
.item-edit h3 {
  text-align: center;
}
.cloudinary-form {
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  background-color: #414141;
  display: grid;
}
.cloudinary-form button {
  grid-column: 1/-1;
  background-color: #8c9ca5;
}
.cloudinary-form input[type="checkbox"] {
  margin: 0 2rem;
}
.current-cloud-photos {
  grid-column: 1/-1;
}
.item-edit input:not(.not),
.item-edit textarea,
.item-edit select {
  /* width: 33.33%; */
  padding: 10px;
  line-height: 1;
  font-size: 1.2rem;
  border: 0;
  border-bottom: 1px solid rgba(30, 29, 28, 0.99);
  border-right: 1px solid rgba(30, 29, 28, 0.99);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  background: #fff;
}
input:focus,
textarea:focus,
select:focus {
  outline: 0;
  background: #d9e6ff;
}
.login-form {
  display: grid;
  grid-gap: 2rem;
}
.contact-form form {
  display: grid;
  grid-gap: 2rem;
  padding: 2rem;
  background-color: #8c9ca5;
}
footer {
  width: 100vw;
  position: absolute;
}
footer p {
  margin: 0;
  display: inline;
}
footer > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #4478e3;
  margin: -10px 0 0 0;
  padding: 0;
  color: #fff;
  /* text-align: center; */
}
footer > div > * {
  margin: 0;
  padding: 0 2rem 3rem 2rem;
  color: #fff;
}
footer * > svg {
  margin: 0 0.5rem -5px 0.5rem;
}
footer div > :last-child {
  text-align: right;
}
.wave {
  animation: wave 3s linear;
  animation-iteration-count: infinite;
  fill: #4478e3;
}
#wave2 {
  animation-duration: 5s;
  animation-direction: reverse;
  opacity: 0.6;
}
#wave3 {
  animation-duration: 7s;
  opacity: 0.3;
}
@keyframes wave {
  to {
    transform: translateX(-100%);
  }
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  z-index: 900;
}
.modal .interior {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 900;
  color: #fff;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  transition: all 0.3s;
}
.modal-open .modal-overlay {
  background: rgba(0, 0, 0, 0.9);
}
.modal-closed .modal-overlay {
  background: rgba(0, 0, 0, 0);
}
.modal-closed .interior {
  display: none;
}
.close-modal {
  color: #fff;
  cursor: pointer;
  float: right;
}
.modal-closed {
  transition: all 0.3s;
  visibility: hidden;
}
.modal-open {
  transition: all 0.3s;
  visibility: visible;
}
.login-form > * {
  padding: 0.5rem 1rem;
}
.login-form span {
  text-align: center;
}
.login-form button {
  border-radius: 2px;
  border: 1px solid #fff;
  background-color: #fff2d9;
}
.login-form button:after {
  background-color: #8c9ca5;
}
.loader {
  position: absolute;
  top: 50%;
  left: 48%;
}
.none {
  display: none !important;
}
@keyframes expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
}
@media (min-width: 768px) {
  .nav .hamburger-button,
  .nav .nav-toggle,
  .nav .shopping-cart {
    display: none;
  }
}
@media (max-width: 1000px) {
  .grid2 {
    padding: 5rem;
  }
  .hero h2 {
    font-size: 13rem;
  }
  #fc {
    font-size: 5rem;
  }
  #fc br {
    display: none;
  }
  .about-p > div {
    font-size: 1.5rem;
    padding: 2rem;
  }
  .footer-content {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .main-sponsor-content img,
  .sponsor-content img {
    height: 200px;
    object-fit: contain;
  }
  .nav-button,
  nav.nav ul a {
    text-align: center;
  }
  .contact-form form {
    padding: 0%;
  }
  .contact-content {
    display: flex;
  }
  .about-p > div {
    background-color: #8c9ca5;
    box-shadow: 1px 1px 10px 0 #414141;
  }
  .hero {
    width: 100%;
  }
  .hero h2,
  h2 {
    font-size: 10rem;
    margin: 0;
    padding: 0;
  }
  .grid2 {
    grid-template-columns: 1fr;
  }
  .schedule {
    grid-row: 1/-1;
  }
  .hashtag,
  .top {
    text-align: center;
  }
  .nav {
    grid-template-columns: 50px 1fr 50px;
    padding: 0;
  }
  .nav ul.closed {
    transform: translateY(-110%);
    z-index: 550;
    position: fixed;
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    height: 100vh;
    background-color: inherit;
  }
  .nav ul.closed li {
    margin: 0 auto;
  }
  .nav ul.closed.open {
    transform: translateY(0);
    overflow: hidden;
    padding: 5em 0;
  }
}
@media (max-width: 650px) {
  .cloudinary-form {
    grid-template-columns: 1fr;
    padding: 2rem;
  }
  .grid2 {
    padding: 2rem;
  }
  .about-p > div {
    font-size: 2rem;
  }
  .main-sponsor-content {
    margin-bottom: 5rem;
  }
  .sponsor-content img {
    width: 50%;
  }
  footer div p,
  footer div > :last-child {
    margin: 0;
    padding: 0 2rem 1rem 2rem;
  }
  .footer-content {
    font-size: 10px;
  }
  footer * > svg {
    margin: 0 0.5rem -10px 0.5rem;
  }
  footer > div > * {
    padding: 0 0.5rem 3rem 0.5rem;
  }
}

@media (max-width: 450px) {
  .contact-image {
    display: none;
  }
  .hero h2,
  h2 {
    font-size: 6rem;
  }
  h3 {
    font-size: 2rem;
  }

  #fc {
    font-size: 4rem;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadein {
  animation: 1s ease-out 0s 1 fadeIn;
}
